import { getGameCode } from '@/page/embed-page/download-in-app/server';
import { launchByScheme } from '../../util';

export default {
  data() {
    return {
      canShow: false,
      blackList: ['ylzt'],
    };
  },
  methods: {
    launchByScheme() {
      launchByScheme();
    },
  },
  created() {
    const gameCode = (getGameCode() || '').trim();
    if (!this.blackList.includes(gameCode)) {
      this.canShow = true;
    }
  },
};
