import { loadSdk, logEvent, getGameCode } from '@/page/embed-page/download-in-app/server';

export function launchApp(params) {
  const blockedGameCodes = [];
  const gameCode = getGameCode();
  if (blockedGameCodes.includes(gameCode)) {
    alert('系统升级，请直接前往腾讯游戏社区内进行查看和处理');
  } else {
    loadSdk().then(() => {
      SX_WEB.app.launch({
        actId: 'sx_game_embed_page_20200716',
        actName: '游戏内嵌页',
        autoInstall: true,
        downloadUrlParams: {
          shanxianinchannel: gameCode,
        },
        ...params,
      });
    });
  }
}

export function launchByScheme() {
  const gameCode = getGameCode();
  logEvent({ eventType: 3, extendId: 1, operId: '1901000010301' });
  launchApp({
    scheme: `tgc://native?moduleName=renowndetail&toWeekly=1&srcType=6&srcId=${gameCode}`,
  });
}
