<template>
  <div
    v-if="bannerInfo && bannerInfo.picUrl"
    class="v-banner"
  >
    <a
      :href="bannerInfo.jumpUrl"
      @click="handleClickBanner"
      class="banner"
      target="_blank"
    >
      <img
        :title="bannerInfo.title"
        :src="bannerInfo.picUrl"
      >
    </a>
  </div>
</template>

<script>
import { getBanner, logEvent } from '../server';

export default {
  name: 'VBanner',
  data() {
    return {
      isRportView: false,
      bannerInfo: {},
    };
  },
  async created() {
    this.bannerInfo = await getBanner();
    if (this.bannerInfo && this.bannerInfo.picUrl) {
      this.$nextTick(() => {
        this.reportView();
      });
    }
  },
  mounted() {
    this.reportView();
  },
  methods: {
    handleClickBanner() {
      logEvent({
        eventType: 3,
        extendId: 9,
        operId: '1901000010309',
        ext1: this.bannerInfo.sourceId,
      });
    },
    reportView() {
      const tagName = (this.$el.tagName || '').toLocaleLowerCase();
      if (tagName === 'div' && window.getComputedStyle(this.$el).display !== 'none' && !this.isRportView) {
        this.isRportView = true;
        logEvent({ eventType: 2, extendId: 9, operId: '1901000010209' });
      }
    },
  },
};
</script>

<style>
</style>
