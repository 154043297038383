<template>
  <Dialog
    :show="dialogShow"
    @change="handleDialogChange"
    class="v-rule"
  >
    <template slot="header">
      <span>{{ rule.title }}</span>
      <i @click.stop="close" />
    </template>
    <template>
      <p v-html="rule.content" />
      <img
        :src="rule.horizontalUrl"
        class="rule-upgrade-pic-horizontal"
      >
      <img
        :src="rule.verticalUrl"
        class="rule-upgrade-pic-vertical"
      >
    </template>
    <template slot="footer">
      <button @click.stop="handleClickButton">
        打开闪现APP了解详情
      </button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/ui/dialog/index.vue';
import { getGameCode, logEvent } from '@/page/embed-page/download-in-app/server';
import { launchApp } from '@/page/embed-page/download-in-app/util';

export default {
  name: 'VRule',
  components: {
    Dialog,
  },
  model: {
    prop: 'dialogShow',
    event: 'dialogChange',
  },
  props: {
    dialogShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rule: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    close() {
      this.dialogShow = false;
      this.handleDialogChange(this.dialogShow);
    },
    handleDialogChange(status) {
      this.$emit('dialogChange', status);
    },
    handleClickButton() {
      const gameCode = getGameCode();
      logEvent({ operId: '1901000010307' });
      launchApp({
        scheme: `tgc://index?tabName=home&srcType=6&srcId=${gameCode}`,
      });
    },
  },
};
</script>

<style>
</style>
