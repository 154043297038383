<template>
  <div
    @click="handleClickLotteryGift(-1)"
    class="v-lottery"
  >
    <div
      ref="wrap"
      :class="['lottery-wrap', `lottery-wrap-${giftList.length}`]"
    >
      <div
        ref="scroll-wrap"
        class="lottery-scroll-wrap"
      >
        <div class="lottery-content">
          <div
            v-for="(item, index) in giftList"
            :key="index"
            :ref="`lottery-item-${index}`"
            :class="['lottery-item', {'multiple': item.giftItems.length > 1, 'disable': item.giftStatus === 1}]"
            @click.stop="handleClickLotteryGift(index)"
          >
            <div class="lottery-item-content">
              <div class="lottery-gift">
                <span>{{ getDayText(index) }}</span>
                <img :src="item.defaultGiftItem.url">
                <i />
              </div>
              <div class="lottery-gift-name-box">
                <div class="lottery-gift-name-content">
                  <span>{{ item.defaultGiftItem.title }}</span>
                  <span>X{{ item.defaultGiftItem.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showDialogIndex >= 0"
      :style="tipsStyle"
      class="gift-dialog-box"
    >
      <div class="gift-dialog">
        <span>{{ getMultipleGiftsText(giftList[showDialogIndex]) }}</span>
      </div>
    </div>
    <button
      :disabled="!canReceiveGift"
      @click="handleClickLotteryButton"
      class="lottery-btn"
    >
      {{ canReceiveGift ? '签到领取' : '已领取' }}
    </button>
  </div>
</template>

<script>
import Vue from 'vue';
import VDialog from '@/page/embed-page/download-in-app/component/dialog.vue';
import { getGameCode, logEvent, signAndReceiveGiftFromGame } from '../server';

export default {
  name: 'VSignGift',
  props: {
    groupId: {
      type: Number,
      default() {
        return 0;
      },
    },
    giftList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showDialogIndex: -1,
      VDialogComponentInstance: null,
    };
  },
  computed: {
    groupScheme() {
      const gameCode = getGameCode();
      return this.groupId ? `tgc://native?moduleName=groupdetail&groupId=${this.groupId}&srcType=6&srcId=${gameCode}` : '';
    },
    receiveGiftIndex() {
      return this.giftList.findIndex(item => item.giftStatus === 0);
    },
    canReceiveGift() {
      return this.receiveGiftIndex >= 0;
    },
    tipsStyle() {
      if (this.showDialogIndex < 0) return {};
      const el = this.$refs[`lottery-item-${this.showDialogIndex}`][0];
      const wrapEl = this.$refs.wrap;
      const scrollEl = this.$refs['scroll-wrap'];
      return {
        top: `${wrapEl.offsetTop + el.offsetTop}px`,
        left: `${wrapEl.offsetLeft + el.offsetLeft + 5 + ((el.offsetWidth - 10) / 2) - scrollEl.scrollLeft}px`,
      };
    },
  },
  watch: {
    giftList: {
      handler(val) {
        this.showDialogIndex = -1;
        if (val.length) {
          this.$nextTick(() => this.reportView());
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const VDialogComponent = Vue.extend(VDialog);
    this.VDialogComponentInstance = new VDialogComponent({
      propsData: {
        show: false,
        scheme: this.groupScheme,
      },
    }).$mount();
    this.$root.$el.append(this.VDialogComponentInstance.$el);
    document.addEventListener('touchstart', () => {
      this.showDialogIndex = -1;
    }, true);
  },
  destroyed() {
    this.$root.$el.removeChild(this.VDialogComponentInstance.$el);
  },
  methods: {
    handleClickLotteryGift(index) {
      this.showDialogIndex = this.showDialogIndex === index ? -1 : index;
      if (index >= 0) {
        logEvent({
          eventType: 3,
          extendId: 11,
          operId: '1901000010311',
          ext6: this.giftList[index].packageId,
          ext7: index + 1,
          ext8: this.getReportGiftRequires(this.giftList[index]),
        });
      }
    },
    handleClickLotteryButton() {
      if (!this.canReceiveGift) {
        this.$toast('目前暂时没有礼包可以领取，请稍后再试~');
        return;
      }
      const index = this.receiveGiftIndex;
      const gift = this.giftList[index];
      signAndReceiveGiftFromGame(gift.packageId).then((res) => {
        if (res.ret === 0) {
          gift.giftStatus = 1;
          logEvent({
            eventType: 6,
            extendId: 1,
            operId: '1901000010601',
            ext6: gift.packageId,
            ext7: index + 1,
            ext8: this.getReportGiftRequires(gift),
          });
          this.$toast('太棒了，领取成功~');
        }
        // 需要 APP 签到
        if (res.ret === 190023) {
          // eslint-disable-next-line no-underscore-dangle
          this.VDialogComponentInstance._props.scheme = this.groupScheme;
          // eslint-disable-next-line no-underscore-dangle
          this.VDialogComponentInstance._props.show = true;
        }
      })
        .catch((err) => {
          this.$toast(`错误[${err.data.ret}]: ${err.data.msg}`);
        });
      logEvent({
        eventType: 3,
        extendId: 12,
        operId: '1901000010312',
        ext6: gift.packageId,
        ext7: index + 1,
        ext8: this.getReportGiftRequires(gift),
      });
    },
    getMultipleGiftsText(item) {
      if (!item) return '';
      return item.giftItems.map(gift => gift.title + (gift.description ? `*${gift.description}` : '')).join('、');
    },
    getReportGiftRequires(gift) {
      if (!gift || !gift.giftRequires || !gift.giftRequires.length) return 0;
      if (gift.giftRequires.includes(0)) return 1;
      if (gift.giftRequires.includes(1)) return 2;
      return 0;
    },
    reportView() {
      const tagName = (this.$el.tagName || '').toLocaleLowerCase();
      if (tagName === 'div' && window.getComputedStyle(this.$el).display !== 'none') {
        this.giftList.forEach((gift, index) => {
          logEvent({
            eventType: 2,
            extendId: 11,
            operId: '1901000010211',
            ext6: gift.packageId,
            ext7: index + 1,
            ext8: this.getReportGiftRequires(gift),
          });
        });
      }
    },
    getDayText(index) {
      const map = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
      return `第${map[index]}天`;
    },
  },
};
</script>
