/**
 * 封装http请求
 */
import axios from 'axios';
import toast from '@/ui/toast/index';
import { gameplusApi } from '@/util/http/index';

const HTTP_TIMEOUT = 30000;
const apiHost = window.location.host.indexOf('mtest.gameplus.qq.com') > -1 ? '//ams-pre.xinyue.qq.com' : '//gameapi.gameplus.qq.com';

function showErrMsg(msg) {
  toast(msg || '未知异常');
}


/**
 * 处理返回值
 * 2，处理通用报错信息
 */
function responseAct(resolve, reject, { status, data, statusText }) {
  const newdata = data;
  if (status === 200) { // http正常返回
    if (newdata.ret === 0 || newdata.errCode === 0) { // 接口正常返回
      resolve(newdata);
    } else {
      showErrMsg(newdata.errMsg);
      reject(newdata);
    }
  } else {
    showErrMsg(statusText);
    reject({ status, statusText });
  }
}

/**
 * @deprecated 废弃这个接口，改用request，统一API调用
 */
function get(path, params = {}) {
  return new Promise((resolve, reject) => axios.get(`${apiHost}/${path}`, {
    params,
    headers: {
      'Content-Type': 'application/json',
      'G-Token': '5188',
      // 'game-code': '',
    },
    timeout: HTTP_TIMEOUT,
    withCredentials: true,
  }).then((resp) => {
    responseAct(resolve, reject, resp);
  }, (err) => {
    showErrMsg(err);
  }));
}

function request(path, params) {
  return gameplusApi.request({
    url: path,
    data: params,
  });
}

export default {
  get,
  request,
};
