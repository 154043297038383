<template>
  <div
    :class="'card'+ rank.rank + (rank.status==0?' lock ':'') + (rank.status==2?' current':'')"
    class="list-card "
  >
    <!-- 特权弹窗按钮 -->
    <Icon
      v-if="rank.currentRank"
      @click="onQuestionIconClick"
      name="question-white"
      size="l"
      class="card-question-icon"
    />
    <!-- card{1-3} lock 上锁-->
    <div class="card-info">
      <div
        v-if="rank.currentRank"
        class="nickname"
      >
        {{ userInfo.roleName }}
      </div>
      <div class="card-lev">
        <i class="lv-l" />
        <i
          :class="rank.status==2&&rank.rank!==5?('lv-m-'+rank.subRank):'hide'"
          class="lv-m"
        />  <!-- lv-m-{1-3}-->
        <i class="lv-r" />
      </div>
      <div class="prestige-num">
        {{ (rank.currentRank ? userInfo.point: rank.minPoint) }}<span>声望值{{ rank.currentRank ? '' : '解锁' }}</span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="rank.rankDesc"
        v-html="rank.rankDesc"
        class="upgrade"
      />
    </div>
    <!-- <div class="uid ">UID. -</div> -->
    <div
      v-if="userInfo.rank==rank.rank&&rank.rank!==5"
      :class="rank.iconUrl?'leve1-'+userInfo.level:''"
      class="icon-lg"
    />
    <div
      v-if="rank.showRadio"
      class="progress-module"
    >
      <i
        :class="'level-' + userInfo.level"
        class="icon-sm"
      />
      <div class="progress-bar">
        <div
          :style="{width:rank.radio}"
          class="progress"
        />
      </div>
      <!-- gold：升级黄金段位 -->
      <i
        :class="'level-' + (userInfo.level+1)"
        class="icon-sm"
      />
    </div>
    <div class="mask" />
  </div>
</template>
<script>

import Icon from '@/ui/icon';

export default {
  name: 'Card',
  components: { Icon },
  props: {
    rank: {
      type: Object,
      default() {
        return {};
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onQuestionIconClick() {
      this.$emit('onShowPriviledge');
    },
  },
};
</script>
