const win = window;
const lib = {};
const doc = win.document;
const docEl = doc.documentElement;
let metaEl = doc.querySelector('meta[name="viewport"]');
const flexibleEl = doc.querySelector('meta[name="flexible"]');
let dpr = 0;
let scale = 0;
let tid;
const flexible = lib.flexible || (lib.flexible = {});
if (metaEl) {
  scale = 1.0;
  dpr = parseInt(1 / scale, 10);
} else if (flexibleEl) {
  const content = flexibleEl.getAttribute('content');
  if (content) {
    const initialDpr = content.match(/initial-dpr=([\d\\.]+)/);
    const maximumDpr = content.match(/maximum-dpr=([\d\\.]+)/);
    if (initialDpr) {
      dpr = parseFloat(initialDpr[1]);
      scale = parseFloat((1 / dpr).toFixed(2));
    }
    if (maximumDpr) {
      dpr = parseFloat(maximumDpr[1]);
      scale = parseFloat((1 / dpr).toFixed(2));
    }
  }
} if (!dpr && !scale) {
  const isIPhone = win.navigator.appVersion.match(/iphone/gi);
  const { devicePixelRatio } = win;
  if (isIPhone) {
    if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
      dpr = 3;
    } else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
      dpr = 2;
    } else {
      dpr = 1;
    }
  } else {
    dpr = 1;
  } scale = 1 / dpr;
}
docEl.setAttribute('data-dpr', dpr);
if (!metaEl) {
  metaEl = doc.createElement('meta');
  metaEl.setAttribute('name', 'viewport');
  metaEl.setAttribute('content', `initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no`);
  if (docEl.firstElementChild) {
    docEl.firstElementChild.appendChild(metaEl);
  } else {
    const wrap = doc.createElement('div'); wrap.appendChild(metaEl); doc.write(wrap.innerHTML);
  }
}
function refreshRem() {
  let { width } = docEl.getBoundingClientRect();
  if (width / dpr > 540) {
    width = 540 * dpr;
  }
  const rem = width / 10; docEl.style.fontSize = `${rem}px`;
  flexible.rem = rem;
}
win.addEventListener('resize', () => {
  clearTimeout(tid); tid = setTimeout(refreshRem, 300);
}, false);
win.addEventListener('pageshow', (e) => {
  if (e.persisted) {
    clearTimeout(tid); tid = setTimeout(refreshRem, 300);
  }
}, false);
if (doc.readyState === 'complete') {
  doc.body.style.fontSize = `${12 * dpr}px`;
} else {
  doc.addEventListener('DOMContentLoaded', () => {
    doc.body.style.fontSize = `${12 * dpr}px`;
  }, false);
}
refreshRem();

flexible.dpr = dpr;
flexible.refreshRem = refreshRem;
export default flexible;
