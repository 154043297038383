<template>
  <div
    id="card-swiper"
    class="card"
  >
    <div
      id="swiper"
      v-if="showSwiper"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in rankInfo"
          :key="index"
          class="swiper-slide"
        >
          <Card
            :rank="item"
            :user-info="userInfo"
            @onShowPriviledge="$emit('onShowPriviledge')"
          />
        </div>
      </div>
    </div>
    <div class="swiper-dot-box">
      <i
        v-for="(item, index) in rankInfo"
        :key="index"
        :class="[ activeIndex === index ? 'active' : '' ]"
      >
        <em :style="{ backgroundColor: dotColorList[index] }" />
      </i>
    </div>
    <v-btn-jump class="card-swiper-btn-jump" />
  </div>
</template>
<script>
/**
 * swiper 宽度是通过卡片宽度反推。
 */


import VBtnJump from '@/page/embed-page/download-in-app/component/btn-jump/index.vue';
import Card from './card.vue';

export default {
  name: 'Cardswiper',
  components: {
    VBtnJump,
    Card,
  },
  props: {
    outerContainerId: {
      // 容器id ，用来计算swiper的位置 默认横屏容器id
      type: String,
      default: 'right-wrap',
    },
    rankInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    show: { // 当前模块是否展示
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      swiper: null, // swiper实例
      showSwiper: true,
      width: '', // swiper 的宽度
      dotColorList: ['#626262', '#E7D48A', '#907B6B', '#4A416E', '#A27621'],
      activeIndex: 0,
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.initSwiper();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initSwiper();
    window.addEventListener('resize', () => {
      if (this.show) { // 当前模块展示才初始化
        this.showSwiper = false;
        this.$nextTick(() => {
          this.showSwiper = true;
          this.$nextTick(() => {
            this.initSwiper();
          });
        });
      }
    });
  },
  methods: {
    initSwiper() {
      const ctx = this;
      if (this.swiper) {
        this.swiper.destroy(true);
      }
      this.rankInfo.forEach((item, index) => {
        if (item.levelInfo.length) {
          this.activeIndex = index;
        }
      });
      this.swiper = new window.Swiper('#swiper', {
        autoplay: false,
        initialSlide: this.activeIndex,
        effect: 'coverflow',
        //  slidesPerView: window.orientation === 180 || window.orientation === 0 ? 1.2 : 1.6, // 横屏1.6 竖屏1.2
        slidesPerView: parseFloat((document.querySelector('#swiper').offsetWidth) / document.querySelector('.current').offsetWidth, 2) + 0.03,
        // 改为自适应的显示
        centeredSlides: true,
        onTransitionEnd(swiper) {
          ctx.activeIndex = swiper.activeIndex;
        },
      });
    },
  },
};
</script>
