<template>
  <div class="v-gift-item">
    <div class="mr-box">
      <div class="header-box">
        <span>{{ gift.packageTitle }}</span>
        <div class="button-box">
          <span v-if="gift.giftType === 2 && gift.giftStatus === 0"><em>{{ buttonText[0] }}</em></span>
          <button
            :disabled="gift.giftStatus === 1"
            @click="handleClickButton"
          >
            {{ gift.giftStatus === 0 ? buttonText[1] : gift.btnUnavailableText }}
          </button>
        </div>
      </div>
      <div class="line" />
    </div>
    <div class="mr-left-box">
      <div class="gift-scroll-wrap">
        <i class="scroll-mask-left" />
        <div class="gift-scroll-content">
          <div
            v-for="(item, index) in gift.giftItems"
            :key="index"
            class="gift-item"
          >
            <div class="icon-box">
              <img
                :title="item.title"
                :src="item.url"
              >
              <i v-if="item.description">{{ item.description }}</i>
            </div>
            <span>{{ item.title }}</span>
          </div>
        </div>
        <i class="scroll-mask-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { getGameCode, logEvent } from '@/page/embed-page/download-in-app/server';
import { launchApp } from '@/page/embed-page/download-in-app/util';

export default {
  name: 'VGiftItem',
  props: {
    gift: {
      type: Object,
      default() {
        return {};
      },
    },
    groupId: {
      type: Number,
      default() {
        return 0;
      },
    },
    gameName: {
      type: String,
      default() {
        return '';
      },
    },
  },
  computed: {
    buttonText() {
      if (this.gift.giftType === 2) {
        return this.gift.btnDefaultText.split(' ', 2);
      }
      return ['', this.gift.btnDefaultText];
    },
  },
  mounted() {
    logEvent({
      eventType: 2,
      extendId: 10,
      operId: '1901000010210',
      ext1: this.gift.packageId,
    });
  },
  methods: {
    handleClickButton() {
      logEvent({
        eventType: 3,
        extendId: 10,
        operId: '1901000010310',
        ext1: this.gift.packageId,
      });
      const gameCode = getGameCode();
      launchApp({
        scheme: `tgc://native?moduleName=gamegift&gameCode=${gameCode}&groupId=${this.groupId}&gamename=${this.gameName}&curTab=${this.gift.giftType === 2 ? 1 : 0}&srcType=6&srcId=${gameCode}`,
      });
    },
  },
};
</script>
