<template>
  <div class="header-top">
    <div class="head-left">
      <img
        :src="userInfo.avatar ? userInfo.avatar.replace('http:', '') : 'https://static.gameplus.qq.com/business/shengwang/ava.png'"
        class="avatar"
      >
      <div class="info">
        <div class="user-info">
          <div class="nickname">
            {{ userInfo.rankName }} {{ userInfo.roleName }}
          </div>
          <i :class="'duan-icon '+ 'level-' + userInfo.level" />
        </div>

        <div class="prestige">
          <span class="pre-text">当前声望值： {{ userInfo.point || '-' }}</span>
          <span
            @click.stop="handleClickTips"
            class="tip"
          />
        </div>
      </div>
    </div>
    <div
      ref="right"
      class="head-right"
    >
      <v-banner />
      <div class="logo" />
    </div>
  </div>
</template>

<script>
import VBanner from '@/page/embed-page/download-in-app/component/banner.vue';
import { logEvent } from '../server';

export default {
  name: 'Userinfo',
  components: {
    VBanner,
  },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleClickTips() {
      if (window.getComputedStyle(this.$refs.right).display === 'none') { // 竖屏
        logEvent({ eventType: 3, extendId: 6, operId: '1901000010306' });
      } else { // 横屏
        logEvent({ eventType: 3, extendId: 2, operId: '1901000010302' });
      }
      this.$emit('clickTips');
    },
  },
};
</script>

<style>
</style>
