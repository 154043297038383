<template>
  <button
    v-show="canShow"
    @click.stop="launchByScheme"
    class="btn-jump"
  />
</template>

<script>
import vm from './vm';

export default {
  ...vm,
  name: 'VBtnJump',
};
</script>
