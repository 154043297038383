<template>
  <div
    v-show="show"
    @click.stop="show = false"
    class="v-dialog"
  >
    <div
      @click.stop="() => {}"
      class="dialog-box"
    >
      <i @click="show = false" />
      <div class="dialog-header" />
      <div class="dialog-content">
        <span>去闪现APP任务中心完成签到任务，即可领取礼包</span>
        <button @click="goToApp">
          进入闪现
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { logEvent } from '@/page/embed-page/download-in-app/server';
import { launchApp } from '@/page/embed-page/download-in-app/util';

export default {
  name: 'VDialog',
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    scheme: {
      type: String,
      default() {
        return '';
      },
    },
  },
  watch: {
    show: {
      handler(val, oldVal) {
        if (val) {
          logEvent({
            eventType: 2,
            extendId: 13,
            operId: '1901000010213',
          });
        }
        // 如果是从显示到隐藏，那么就证明是关闭了弹窗
        if (oldVal && !val) {
          logEvent({
            eventType: 3,
            extendId: 13,
            operId: '1901000010313',
            ext1: 1,
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    goToApp() {
      logEvent({
        eventType: 3,
        extendId: 13,
        operId: '1901000010313',
        ext1: 2,
      });
      launchApp({
        scheme: this.scheme,
      });
    },
  },
};
</script>
