/**
 * 初始化用户数据
 */
import { camelCase, isArray, isObject } from 'lodash';
import { checkEnv } from '@/util/browser-env';
import ajax from './source/ajax';
import report from '../../../api/report';

const serverData = {};
const apiHost = window.location.host.indexOf('mtest.gameplus.qq.com') > -1 ? '//ams-pre.xinyue.qq.com' : '//gameapi.gameplus.qq.com';

/**
 * 对象递归下划线转驼峰
 * @param {} data
 */

function objCameCae(data) {
  let newData;
  if (isArray(data)) { // 递归处理数组
    newData = [];
    data.forEach((value) => {
      newData.push(objCameCae(value));
    });
  } else if (isObject(data)) { // 递归处理obj
    const keys = Object.keys(data);
    newData = {};
    keys.forEach((key) => {
      newData[camelCase(key)] = objCameCae(data[key]);
    });
  } else { // 普通值
    return data;
  }
  return newData;
}

// function getUrlParam (name) {
//   const { search } = window.location;
//   const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
//   const r = search.substr(1).match(reg);
//   if (r != null) return decodeURI(r[2]);
//   return null;
// }

function getParamFromUrl() {
  const query = window.location.search;
  const queryObj = query && query.slice(1).split('&')
    .reduce((result, item) => {
      const [key, value] = item.split('=');
      return {
        ...result,
        [key]: value,
      };
    }, {});
  // const queryObj = {
  //   game_code: 'xw2',
  //   version: 'v1',
  // eslint-disable-next-line max-len
  //   code: 'ewogICAgICAgImdhbWVfY29kZSI6Inl4emoiLAogICAgICAgInZlcnNpb24iOiJWMS4wIiwKICAgICAgICJvcGVuaWQiOiJvd2FubHNzbXUtSHBtN3p6S0JvWHZTYjhMV3BVIiwKICAgICAgICJhcHBpZCI6Ind4OTVhM2E0ZDdjNjI3ZTA3ZCIsCiAgICAgICAidG9rZW4iOiJ0b2tlbiIsCiAgICAgICAiYXJlYWlkIjo0LAogICAgICAgInBsYXRpZCI6MCwKICAgICAgICJwYXJ0aXRpb25pZCI6NDAyOSwKICAgICAgICJwYXJ0aXRpb25fbmFtZSI6IuW-ruS_oTE55Yy6LeaXoOaDheWGsumUiyIsCiAgICAgICAicm9sZWlkIjoib3dhbmxzc211JTJESHBtN3p6S0JvWHZTYjhMV3BVIiwKICAgICAgICJyb2xlX25hbWUiOiLosLflupXooYzlvpIiLAogICAgICAgImF2YSI6Imh0dHBzOi8vc3RhdGljLmdhbWVwbHVzLnFxLmNvbS9idXNpbmVzcy9nYW1laDUvaWNvbi1pbnRlZ3JhbC5wbmciLAogICAgICAgInZpcF9sZXZlbCI6NSwKICAgICAgICJ2aXBfaWNvbiI6Imh0dHBzOi8vc3RhdGljLmdhbWVwbHVzLnFxLmNvbS9idXNpbmVzcy9zaGVuZ3dhbmcvaWNvbl8zQDJ4LnBuZyIsCiAgICAgICAidCI6MTIzNDQ1NTY2LAogICAgICAgInIiOjk5OTksCiAgICAgICAiZGV2aWNlIjoiaW9zOSIsCiAgICAgICAiY2hhbm5lbGlkIjoieXh6ajEuNSIsCiAgICAgICAidnQiOjAsCiAgICAgICAibXNka3MiOiIqKioqIgogICB904cc9d270fc04af4',
  // };
  return queryObj;
}

export function getGameCode() {
  const queryObj = getParamFromUrl();
  return (queryObj ? queryObj.game_code : '');
}

// eslint-disable-next-line import/prefer-default-export
export async function getUserInfo() {
  const rspData = await ajax.request('community.RenownSrv/GameBuiltinH5GetRenown', getParamFromUrl());
  const data = objCameCae(rspData);
  serverData.point = data.userInfo.point;
  serverData.level = data.userInfo.level;
  serverData.openId = data.gameInfo.openid;
  serverData.appId = data.gameInfo.appid;
  serverData.gameCode = data.gameInfo.gameCode;

  // 数据处理
  data.userInfo.avatar = data.gameInfo.ava;
  try {
    data.userInfo.roleName = decodeURIComponent(data.gameInfo.roleName);
  } catch (error) {
    this.$logger.error('decodeURIComponent', error);
    data.userInfo.roleName = '';
  }
  let { rankInfo } = data;
  rankInfo = rankInfo.map((item) => {
    let { subRank, currentRank, radio, showRadio } = item;
    if (item.levelInfo.length) {
      const curr = item.levelInfo[0]; // 当前段位
      subRank = curr.subRank;
      currentRank = (item.status === 2);
      if (curr.maxPoint !== -1) { // -1 顶级
        radio = `${((data.userInfo.point - curr.minPoint) / (curr.maxPoint - curr.minPoint)) * 100}%`; // 计算进度条宽度
        showRadio = true;
      }
    }
    return {
      ...item,
      subRank,
      currentRank,
      radio,
      showRadio,
      rankDesc: item.rankDesc.replace(/(\d+)/, '<span>$1</span>'),
    };
  });
  data.upRule.content = data.upRule.content.replace('「', '<span>「').replace('」', '</span>」');

  return {
    ...data,
    rankInfo,
  };
}

// 具体上报数据： https://docs.google.com/spreadsheets/d/1Z05-nKg4yEBq-4jvM5b5SqArUtTXjUjN5KDaEZmJQkM/edit#gid=1177034808
export function logEvent(parameter) { // 最后一个参数 openid、声望值和等级
  if (!serverData || !serverData.gameCode) {
    ((parame) => {
      setTimeout(() => {
        logEvent(parame);
      }, 200);
    })(parameter);
    return;
  }
  const {
    eventType, extendId, operId, ext1, ext6, ext7, ext8,
  } = parameter;
  const data = {
    pageid: 1901, // 页面ID
    moduleid: 1, // 操作对象ID
    eventtype: eventType, // 操作类型ID
    extendid: extendId, // 保留字段
    operid: operId, // EventCode
    appid: serverData.appId,
    gamename: serverData.gameCode,
    ext2: serverData.openId, // openid
    ext3: serverData.level, // 等级
    ext4: serverData.point, // 声望值
    ext5: ['all', 'horizontal', 'vertical'].indexOf(window.location.pathname.split('/').pop()), // ext5用来表示横竖屏：0 兼容，1 横屏 2竖屏
  };
  // 这些是可选上报字段
  ['contentid', 'staytime', 'contenttype'].forEach((key) => {
    if (key in parameter) {
      data[key] = parameter[key];
    }
  });
  if (ext1) {
    data.ext1 = ext1;
  }
  if (ext6) {
    data.ext6 = ext6;
  }
  if (ext7) {
    data.ext7 = ext7;
  }
  if (ext8) {
    data.ext8 = ext8;
  }
  report(data);
}

// 惰性加载js
export function loadSdk() {
  if (window.SX_WEB) return Promise.resolve();
  // eslint-disable-next-line no-underscore-dangle
  if (window.__SX_LOAD_SDK) {
    return new Promise((resolve) => {
      const timer = setInterval(() => {
        if (window.SX_WEB) {
          clearInterval(timer);
          resolve();
        }
      }, 200);
    });
  }
  // eslint-disable-next-line no-underscore-dangle
  window.__SX_LOAD_SDK = true;
  return new Promise((resolve, reject) => {
    const headTag = document.querySelector('head');
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.onload = () => {
      resolve(true);
    };
    scriptTag.onerror = (e) => {
      reject(e);
    };
    scriptTag.src = '//static.gameplus.qq.com/wap/common/sdk/gameCommunityJsSdk-1.0.js';
    headTag.appendChild(scriptTag);
  });
}

export async function getBanner() {
  const configList = await loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GCCommonConfSvr/GetGCCommonConfCommonSourceConf`,
    data: {
      game_code: getGameCode(),
      platform: '0',
      scene_id: '1',
    },
  }))
    .then(res => res.configList || [])
    .catch(() => []);
  const platform = checkEnv().android ? 1 : 2;
  const time = parseInt((new Date()).getTime() / 1000, 10);
  return configList.filter((item) => {
    const itemPlatform = Number(item.platform);
    return !itemPlatform || itemPlatform === platform;
  }).find(item => time >= item.start && time <= item.end);
}

export function getGiftList() {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GiftSrv/GetGiftsFromGame`,
    data: {
      game_code: getGameCode(),
      scene: 'all',
    },
    options: {
      excludeRet: [90404],
    },
  }))
    .then(res => (res.gift || []).filter(item => item.giftStatus === 0))
    .catch(() => []);
}

export function getGameInfo() {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GameSrv/GetGameInfo`,
    data: {
      game_code: getGameCode(),
    },
  }))
    .then(res => res.gameInfo);
}

export function getGroupId() {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GroupSrv/GetGroupIds`,
    data: {
      game_code: getGameCode(),
    },
  }))
    .then(res => Number(res.gids[0]) || 0);
}

export function getSignGifts() {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GiftSrv/GetSignGifts`,
    data: {
      game_code: getGameCode(),
    },
    options: {
      excludeRet: [90404],
    },
  }))
    .then(res => (res.gift || []))
    .then(gifts => gifts.map(item => ({
      ...item,
      defaultGiftItem: item.defaultGiftItem || item.giftItems[0],
    })))
    .catch(() => []);
}

export function signAndReceiveGiftFromGame(packageId) {
  const queryObj = getParamFromUrl();
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GiftSrv/SignAndReceiveGiftFromGame`,
    data: {
      game_code: getGameCode(),
      package_id: packageId,
      code: queryObj.code,
    },
    options: {
      excludeRet: [190023],
    },
  }));
}


export function getFeedsList({ context }) {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GCCommonConfSvr/GetGCCommonConfCommonFeedsConf`, // TODO host换成自动
    data: {
      game_code: getGameCode(),
      platform: '0',
      scene_id: '2',
      context,
    },
  }));
}

export function getPostInfo({ postId, postType }) {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.GCFeedDetailSrv/GetHomePageFeedsDetail`,
    data: {
      feedId: Number(postId),
      postType,
    },
  }));
}

// 帖子已读
export function readPostAction({
  postId, postType, postCreatorId, groupId,
}, options = {}) {
  return loadSdk().then(() => SX_WEB.http.request({
    url: `${apiHost}/community.PostSrv/PostAction`,
    data: {
      postId,
      postType,
      postCreatorId,
      userId: 0,
      groupId,
      action: 'read',
    },
    options: {
      ...options,
    },
  }));
}

export const autoJumpGameCode = ['ty', 'tlbb', 'yxsm']; // 自动跳转的游戏
