<template>
  <div class="v-card-gift">
    <div class="scroll-wrap">
      <div class="scroll-content">
        <v-sign-gift
          v-if="signGiftList.length"
          :group-id="groupId"
          :gift-list="signGiftList"
        />
        <template v-if="giftList.length > 0">
          <div class="gift-box-title">
            <span>闪现一下专属礼包</span>
          </div>
          <div class="gift-card-box">
            <v-gift-item
              v-for="(item, index) in giftList"
              :key="index"
              :gift="item"
              :group-id="groupId"
              :game-name="gameInfo.name"
              :class="['gift-card-item', !showAll && index >= 2 && 'hide']"
            />
          </div>
        </template>
      </div>
    </div>
    <div
      v-show="!showAll && giftList.length > 2"
      @click="showAll = true"
      class="more-box"
    >
      <span>展开全部{{ giftList.length || 0 }}个礼包</span>
      <i />
    </div>
  </div>
</template>

<script>
import VGiftItem from '@/page/embed-page/download-in-app/component/gift-item.vue';
import VSignGift from '@/page/embed-page/download-in-app/component/sign-gift.vue';
import { getGameInfo } from '../server';

export default {
  name: 'VCardGift',
  components: {
    VSignGift,
    VGiftItem,
  },
  props: {
    groupId: {
      type: Number,
      default() {
        return 0;
      },
    },
    giftList: {
      type: Array,
      default() {
        return [];
      },
    },
    signGiftList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showAll: false,
      gameInfo: {},
    };
  },
  async created() {
    this.gameInfo = await getGameInfo();
  },
};
</script>
